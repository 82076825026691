* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'IBM Plex Mono', monospace;
  font-weight: 100;
}

@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@100&display=swap');

.dropdown {
  box-shadow: 0px 30px 35px -30px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.2s ease-in-out;
}

.dropdown:hover {
  box-shadow: 0px 30px 35px -30px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: var(--grey3);
  border-radius: 4px;
}

::-webkit-scrollbar-corner {
  background: var(--grey1);
}
